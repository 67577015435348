import React from 'react';
import { graphql } from 'gatsby';
import Content, { HTMLContent } from '../components/Content';
import Layout from '../components/Layout';
import { Row, Col } from 'antd';

export const AboutPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <Layout title="About | I Profess Daily">
      <Row style={{ marginTop: 48 }}>
        <Col
          xs={{ span: 18, offset: 3 }}
          sm={{ span: 18, offset: 3 }}
          md={{ span: 18, offset: 3 }}
          lg={{ span: 18, offset: 3 }}
          xl={{ span: 18, offset: 3 }}
        >
          <img
            src="https://images.unsplash.com/photo-1526392060635-9d6019884377?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"
            alt=""
          />
          <div style={{ padding: '16px 0' }}>
            <h1>{title}</h1>

            <PageContent className="content" content={content} />
            <div className="social-wrapper">
              <div className="social">
                <a
                  href="https://www.facebook.com/iprofessdaily"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook-f" />
                </a>
                <a
                  href="https://twitter.com/drvilmavega"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-twitter" />
                </a>
                <a
                  href="https://www.instagram.com/vmvdoc"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <AboutPageTemplate
      contentComponent={HTMLContent}
      title={post.frontmatter.title}
      content={post.html}
    />
  );
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
